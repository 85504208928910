import { HandShakeIcon } from "@/components/icons/handshake-icon";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/avatar";

DropdownUserSection.IconHandShake = IconHandShake;

export function DropdownUserSection({
  name,
  image,
  icon,
  className,
}: {
  name: string;
  image: string;
  icon: JSX.Element;
  className: string;
}): JSX.Element {
  return (
    <div className={className}>
      <div className="flex items-center gap-x-2">
        <Image name={image} />

        <UserName name={name} />
      </div>

      {icon}
    </div>
  );
}

function IconHandShake(): JSX.Element {
  return (
    <div>
      <p className="flex h-[15px] w-[30px] items-center justify-evenly border border-green-200 bg-green-200/20 px-1 text-[11px] text-green-200">
        <HandShakeIcon className="h-3 w-3" />7
      </p>
    </div>
  );
}

function UserName({ name }: { name: string }): JSX.Element {
  return <span className="font-diablo text-sm uppercase">{name}</span>;
}

function Image({ name }: { name: string }): JSX.Element {
  return (
    <Avatar asChild>
      <button
        className="items-center justify-center rounded bg-background-subtle"
        type="button"
      >
        <AvatarImage alt="@shadcn" className="rounded" src={name} width={50} />
        <AvatarFallback>CN</AvatarFallback>
      </button>
    </Avatar>
  );
}
